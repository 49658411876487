export enum PageType {
  LANDING = 'landing',
  PAYMENT = 'payment',
  PAYMENT_SUCCESS = 'paymentSuccess',
  PAYWALL = 'paywall',
  RESULT = 'result',
  REGISTER = 'register',
  REGISTEROTP = 'registerOtp',
  QUIZ = 'quiz'
}

export type PageResponse = {
  meta: {
    requestId: string;
    httpStatus: number;
    message?: string;
  };
  result: {
    siteFavicon: string | null;
    appName: string | null;
    appLogo: string | null;
    redirectTo?: string | null;
    bypassRegister?: boolean;
    registerType?: 'email' | 'phoneNumber';
    phoneNumberAllowedCountries?: string[];
    countryCode?: string;
    registerOnPayment?: boolean;
    applicationLinks?: {
      genericDownloadUrl?: string;
      appStoreUrl?: string;
      googlePlayStoreUrl?: string;
      amazonStoreUrl?: string;
      microsoftStoreUrl?: string;
      huaweiAppGalleryUrl?: string;
      customerSupportUrl?: string;
      privacyUrl?: string;
      termsOfServiceUrl?: string;
    };
    social?: {
      googleLogin: boolean;
      facebookLogin: boolean;
      appleLogin: boolean;
    };
    pageOrder: string[];
    preview: boolean;
    isStoreFlow: boolean;
    storeHash: string;
    gtmData: { isActive: boolean; gtmCode: string };
    gaData: {
      isActive: boolean;
      gaCode: string;
    };
    facebookData: {
      isActive: boolean;
      pixelId: string;
      appId: number;
      accessToken: string
    };
    languageData: {
      language: string;
      isRightAlignment: boolean;
    };
    flowPage: {
      id: number;
      appId: number;
      teamId: number;
      flowId: number;
      templateId: number;
      pollId: number;
      title: string;
      order: number;
      status: 1 | 0;
      completionStatus: string;
      createDate: string;
      updateDate: string;
      themeId: null;
      page: string;
      template: {
        id: number;
        name: string;
        title: string;
        batch: string;
        defaultOrder: number;
        status: 1 | 0;
        isMandatory: 1 | 0;
        isStatusChangeable: 1 | 0;
        createDate: string;
        updateDate: string;
      };
      flow: {
        id: number;
        appId: number;
        teamId: number;
        name: string;
        previewHash: null | string;
        status: string;
        rejectionCount: number;
        createDate: string;
        updateDate: string;
        settings: string | null;
      };
      locales: Record<string, {
        id: number;
        appId: number;
        flowId: number;
        flowPageId: number;
        language: string;
        locale: string;
        name: string;
      }>;
    };
    providers: {
      stripe: boolean;
      creditCard: boolean;
      paypal: boolean;
    };
    isPolicyRequired?: boolean;
    epinDetails?: {
      packageId?: number,
      usage?: {
        language: string;
        content: string;
      }[],
      epinCodeAvailable?: boolean,
      epinCode?: string
    }
  };
};

export type LocalizationResponse = {
  meta: {
    requestId: string;
    httpStatus: number;
    message?: string;
  };
  result: Record<string, any>;
};

export type FetchImagesResponse = {
  meta: PageResponse['meta'];
  result: string[];
};
